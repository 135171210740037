import React from "react"
import { Link } from "gatsby"
import { Grid, Box, Flex, Button, Divider } from "theme-ui"

export default function Pagination({ parent, prev, next }) {
  return (
    <>
      <Grid variant="base">
        <Divider />
      </Grid>
      
      <Grid variant="base">
        <Flex sx={{ justifyContent: "space-between" }}>
          {prev ?
            <Link to={`/${parent}/${prev}`}>
              <Button variant="tertiary" sx={{ ml: -1 }}>
                ⟵ {parent === "roles" ? "The" : null} {prev.replace(/-/g, ' ')}
              </Button>
            </Link>
            : <Box />
          }
            
          {next ?
            <Link to={`/${parent}/${next}`}>
              <Button variant="tertiary" sx={{ mr: -1 }}>
                {parent === "roles" ? "The" : null} {next.replace(/-/g, ' ')} ⟶
              </Button>
            </Link>
            : <Box />
          }
        </Flex>
      </Grid>
    </>
  )
}
