import React from "react"
import { Text } from "theme-ui"
import Layout from "../../components/layout"
import RoleTemplate from "../../components/roles/RoleTemplate"
import Pagination from "../../components/Pagination"

import portraitImage from "../../images/role-portraits/magician-cutout.png"
import magician01Image from "../../images/ability-cards/magician-03.png"
import magician02Image from "../../images/ability-cards/magician-02.png"
import magician03Image from "../../images/ability-cards/magician-01.png"
import magician04Image from "../../images/ability-cards/magician-04.png"

import YAMLData from "../../data/learning-paths/magician.yaml"

const MagicianPage = () => (
  <Layout>
    <RoleTemplate
      name="magician"
      src={portraitImage}
      bgGradient="45deg, #D75A94, #EC9B7F"
      introDescription={
        <>
          <Text>The Magician flicks their wrist, delighting an audience with a parade of illusory animals. They mesmerize an adversary, freezing them in place. They peer beyond the eyes of another, entering their dreams and shaping their reality.</Text>
          <Text>Choose The Magician if you want to use spells that affect the mind and the senses. The role is a great choice for people who want to play a dazzling performer, or a devious manipulator.</Text>
        </>
      }
      imgWidth={740}
      imgLeftPos="auto"
      imgRightPos={[2, 4, 6]}
      abilityDescription="The Magician is a magical trickster who can bring deception and mayhem to almost any scene. This role is especially great at coming up with clever ways to deal with problems, especially in ways that don’t require combat or hurting other creatures. Whether you want to pull a fast one on a merchant or pull off a big heist, The Magician is a great choice for sly players."
      abilityImg01={magician01Image}
      abilityImg02={magician02Image}
      abilityImg03={magician03Image}
      abilityImg04={magician04Image}
      yaml={YAMLData}
    />

    <Pagination parent="roles" prev="spy" next="wizard" />
  </Layout>
)

export default MagicianPage
