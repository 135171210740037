import React from "react"
import { Grid, Box, Text, Image } from "theme-ui"
import SEO from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"

export default function RoleIntro({ name, src, bgGradient, introDescription, imgWidth, imgLeftPos, imgRightPos, abilityDescription, abilityImg01, abilityImg02, abilityImg03, abilityImg04, yaml }) {
  return (
    <>
      <SEO title={`The ${name.charAt(0).toUpperCase() + name.slice(1)}`} />
    
      <Breadcrumbs
        title="Role"
        headline={`The ${name.charAt(0).toUpperCase() + name.slice(1)}`} />

      <Grid variant="base" columns={["1fr", "1fr 6fr 1fr", "1fr 5fr 1fr"]}>
        <Box />
        <Box sx={{ textAlign: "center" }}>
          <Text variant="bodyLarge" sx={{ mb: 6 }}>
            {introDescription}
          </Text>
        </Box>
        <Box />
      </Grid>

      <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: 1600,
          mx: "auto",
          backgroundImage: `linear-gradient(${bgGradient})`,
          borderBottom: "1px solid",
          mb: [6, 8],
          pt: [7, 8],
          mt: 8
        }}
      >
        <Image
          src={src}
          alt=""
          sx={{
            display: "block",
            position: "relative", 
            width: imgWidth, 
            mt: [-192, -240],
            mx: "auto"
          }}
        />
      </Box>

      <Grid variant="base" columns={[1, 2, 2, "1fr 2fr"]} sx={{ alignItems: "center", mb: [7, 8], px: [2, 4, 8], overflowX: "visible" }}>
        <Box sx={{ position: "relative", height: 550, my: [6, 0] }}>
          <Image src={abilityImg04} sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-45%, -55%) rotate(5deg)", width: 300, boxShadow: "0 4px 16px rgba(0, 0, 0, 0.2)", borderRadius: 16 }} />
          <Image src={abilityImg03} sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-55%, -50%) rotate(-3deg)", width: 300, boxShadow: "0 4px 16px rgba(0, 0, 0, 0.2)", borderRadius: 16 }} />
          <Image src={abilityImg02} sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -45%)", width: 300, boxShadow: "0 4px 16px rgba(0, 0, 0, 0.2)", borderRadius: 16 }} />
          <Image src={abilityImg01} sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -48%) rotate(6deg)", width: 300, boxShadow: "0 4px 16px rgba(0, 0, 0, 0.2)", borderRadius: 16 }} />
        </Box>
        <Box>
          <Text as="h2" variant="title02">Ability Showcase</Text>
          <Text as="p" variant="body">{abilityDescription}</Text>
        </Box>
      </Grid>

      <Grid variant="base" columns={1}>
        <Grid gap={[7, 8]}>
          {yaml.paths.map((data) => (
            <Box key={data} sx={{ borderTop: "1px solid", borderColor: name, pt: 3 }}>
              <Text as="h3" variant="title03" sx={{ mb: 2, color: name }}>{data.path.name}</Text>
              
              {data.path.abilities.length > 0 ? (
                <Grid columns={[1, 1, 1, 5]}>
                  {data.path.abilities.map((ability) => (
                    <Box key={ability}>
                      <Text as="h3" variant="label">{ability.item.name}</Text>
                      <Text as="p" variant="body">{ability.item.description}</Text>
                    </Box>
                  ))}
                </Grid>
              ) : null }
            </Box>
          ))}
        </Grid>
      </Grid>
    </>
  )
}
